<template>
  <div class="examples">
    <PassportBreadcrumbs :parentRoute="this.$route.meta.parentComponent" :currentRoute="this.$route.name" />
    <span class="page-heading">Edit Target </span>
    <hr class="line-break" />
    <h2 class="category-heading">Target Information</h2>
    <div class="target-info">
      <div>
        <span class="input-header"> Target Name* </span>
        <PassportInput class="target-name" v-model="targetName" placeholder="Type your target name..." required />
        <span
          :class="{ 'input-instructions': !isError || targetName, 'input-instructions__error': isError && !targetName }"
        >
          Give this target a name.
        </span>
      </div>
      <div>
        <span class="input-header"> Group* </span>
        <PassportDropdown
          class="dropdown"
          :options="groupOptions"
          v-model="selectedGroup"
          trackBy="type"
          label="label"
          placeholder="Select group"
        />
        <span
          :class="{
            'input-instructions': !isError || selectedGroup,
            'input-instructions__error': isError && !selectedGroup,
          }"
        >
          Select this group in which the target belongs to.
        </span>
      </div>
    </div>
    <div class="target-description">
      <span class="input-header"> description* </span>
      <PassportInput v-model="targetDescription" class="description-text" type="textarea" />
      <span
        :class="{
          'input-instructions': !isError || targetDescription,
          'input-instructions__error': isError && !targetDescription,
        }"
      >
        Add a description to this target.
      </span>
    </div>
    <div class="point-holder">
      <span class="input-header"> Point value* </span>
      <PassportInput class="points-input" v-model="points" placeholder="" />
      <span
        :class="{
          'input-instructions': !isError || points,
          'input-instructions__error': isError && !points,
        }"
      >
        Enter how many points a user will earn when they scan and complete this target.
      </span>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">Target Setup</h2>
    <div class="point-holder">
      <span class="input-header"> Target Type* </span>
      <PassportDropdown
        class="dropdown"
        :options="targets"
        v-model="selectedTargetObject"
        trackBy="type"
        label="label"
      />
      <span
        :class="{
          'input-instructions': !isError || selectedTarget,
          'input-instructions__error': isError && !selectedTarget,
        }"
      >
        Select a target type. Please note: To setup a trivia or personality quiz target, you must first create the asset
        on the Trivia & Quizzes page.
      </span>
    </div>
    <div v-if="selectedTarget === 'image'" class="target-type">
      <span class="input-header"> Target {{ selectedTargetObject.type }}* </span>
      <div class="image-holder">
        <PassportImageUploader
          ref="imageSrc"
          class="image-upload"
          key="instance-0"
          :imageWidth="350"
          :imageHeight="200"
          :maxFileSize="10000000"
          :presignedUrlParams="{ type: 'book', item: 'cover' }"
        />
      </div>
      <span
        :class="{
          'input-instructions': !imageSrcError,
          'input-instructions__error': imageSrcError,
        }"
      >
        Upload an image for this target. Acceptable file types: PNG, JPG, JPEG. File size limit: 10MB
      </span>
    </div>
    <div v-else-if="selectedTarget === 'video'" class="target-type">
      <span class="input-header"> Target {{ selectedTarget }}* </span>
      <div class="image-holder">
        <PassportImageUploader
          ref="videoSrc"
          class="image-upload"
          key="instance-1"
          :imageWidth="350"
          :imageHeight="200"
          :maxFileSize="30000000"
          :presignedUrlParams="{ type: 'book', item: 'cover' }"
        />
      </div>
      <span
        :class="{
          'input-instructions': !videoSrcError,
          'input-instructions__error': videoSrcError,
        }"
      >
        Upload a video for this target. Acceptable file types: MP4. File size limit: 30MB
      </span>
      <div class="thumbnail-uploader">
        <p class="input-header">Video Thumbnail*</p>
        <div class="image-holder">
          <PassportImageUploader
            ref="videoThumbnail"
            class="image-upload"
            key="instance-2"
            :imageWidth="350"
            :imageHeight="200"
            :maxFileSize="10000000"
            :presignedUrlParams="{ type: 'book', item: 'cover' }"
          />
        </div>
        <span
          :class="{
            'input-instructions': !videoThumbError,
            'input-instructions__error': videoThumbError,
          }"
        >
          Upload a thumbnail for this video. Acceptable file types: PNG, JPG, JPEG. File size limit: 10MB
        </span>
      </div>
    </div>

    <div v-else-if="selectedTarget === 'link'" class="target-type">
      <div class="target-info">
        <div>
          <span class="input-header"> Target Link Title* </span>
          <PassportInput v-model="linkTitle" placeholder="Type your link title..." />
          <span
            :class="{
              'input-instructions': !linkError || linkTitle,
              'input-instructions__error': linkError && !linkTitle,
            }"
          >
            Enter your link title.
          </span>
        </div>
        <div>
          <span class="input-header"> Target Link URL* </span>
          <PassportInput v-model="linkPath" placeholder="URL" />
          <span
            :class="{
              'input-instructions': !linkError || linkPath,
              'input-instructions__error': linkError && !linkPath,
            }"
          >
            Enter URL for this target.
          </span>
        </div>
      </div>
    </div>
    <div v-else-if="selectedTarget === 'quiz'" class="target-type">
      <div class="target-info">
        <div>
          <span class="input-header"> Select personality quiz * </span>
          <PassportDropdown
            class="dropdown"
            :options="personalityOptions"
            v-model="selectedQuiz"
            trackBy="type"
            label="label"
          />
          <span
            :class="{
              'input-instructions': !quizError || selectedQuiz,
              'input-instructions__error': quizError && !selectedQuiz,
            }"
          >
            Select which personality quiz to assign to this target.
          </span>
        </div>
      </div>
    </div>
    <div v-else-if="selectedTarget === 'trivia'" class="target-type">
      <div class="target-info">
        <div>
          <span class="input-header"> Select trivia * </span>
          <PassportDropdown
            class="dropdown"
            :options="triviaOptions"
            v-model="selectedTrivia"
            trackBy="type"
            label="label"
          />
          <span
            :class="{
              'input-instructions': !triviaError || selectedTrivia,
              'input-instructions__error': triviaError && !selectedTrivia,
            }"
          >
            Select which trivia asset to assign to this target.
          </span>
        </div>
      </div>
    </div>
    <hr class="line-break" />
    <div class="group-type">
      <h2 class="category-heading">Stamp</h2>
      <span class="input-header"> Upload Stamp </span>
      <div class="stamp-upload">
        <PassportImageUploader
          ref="stampImage"
          class="image-upload"
          key="instance-0"
          :imageWidth="350"
          :imageHeight="200"
          :maxFileSize="10000000"
          :presignedUrlParams="{ type: 'book', item: 'cover' }"
          v-model="stamp"
          @fileRemovedAction="fileRemoved"
        />
      </div>
      <span class="input-instructions">
        Upload the stamp the user will receive when they find this target. File must be a PNG, JPEG or JPG and be under
        10 MB in size.
      </span>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">Hint (Optional)</h2>
    <div class="switch-holder">
      <span class="input-header"> Hint </span>
      <PassportSwitch
        class="switch"
        v-model="hintSwitch"
        @click="hintSwitch ? (showModal = false) : (showModal = true)"
        :label="hintSwitch ? 'Enabled' : 'Disabled'"
      />
      <span class="input-instructions">
        When enabled, you can add a hint to this stamp. Users will be able to reveal this hint before finding the target
        associated to this stamp.
      </span>
      <div v-if="hintSwitch" class="hint">
        <div class="hint-title">
          <div>
            <span class="input-header"> Hint title </span>
            <PassportInput class="points-input" v-model="hint" placeholder="Here's a hint" />
            <span class="input-instructions">
              Add a title to your hint. If no title is added, the default “Here’s a hint!” will be used.
            </span>
            <div class="hint-description">
              <span class="input-header"> Hint description* </span>
              <PassportInput
                class="points-input"
                v-model="hintDescription"
                type="textarea"
                placeholder="Add a description to this hint…"
              />
              <span
                :class="{
                  'input-instructions': !hintError || hintDescription,
                  'input-instructions__error': hintError && !hintDescription,
                }"
              >
                Add a description to this hint. This description should help a user to find this target.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">QR Code</h2>
    <div class="qr-holder">
      <span class="input-header"> QR code </span>
      <div @click="downloadQRCode" class="download-button">
        <span> Download </span>
        <DownloadIcon class="download-icon" />
      </div>
      <span class="input-instructions">
        Your QR code will generate after this target is saved. After it has been generated, you can download the QR code
        here or from the Manage Targets page.
      </span>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">Stamp</h2>
    <div class="switch-holder">
      <span class="input-header"> Status </span>
      <PassportSwitch class="switch" v-model="published" :label="published ? 'Published' : 'Draft'" />
      <span class="input-instructions"> Targets must be published to appear in a group. </span>
    </div>
    <hr class="line-break" />
    <div class="buttons-container">
      <PassportButton label="Save Target" @click="errorValidation" />
      <PassportButton variant="text secondary" to="/targets" label="Cancel" />
    </div>
    <PassportModal disableClosingOnMask v-model="showDraftModal">
      <div class="section">
        <div class="icon-container">
          <DraftIcon class="icon" />
        </div>
        <span class="modal-heading">Target Still Draft</span>
        <hr class="modal-separator" />
        <div class="choice-text">
          <span> Please note: This target is still a draft and will not appear on the platform until published. </span>
        </div>
        <div class="button-container">
          <PassportButton label="okay" class="okay-button" @click="saveTarget" />
          <PassportButton
            variant="text"
            label="Do not show this warning again"
            class="warning-button"
            @click="warning"
          />
        </div>
      </div>
    </PassportModal>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import PassportInput from '@/components/PassportInput.vue';
import PassportDropdown from '@/components/PassportDropdown.vue';
import PassportSwitch from '@/components/PassportSwitch.vue';
import DownloadIcon from '@/assets/icons/download_icon.svg';
import PassportImageUploader from '@/components/PassportImageUploader.vue';
import PassportBreadcrumbs from '@/components/PassportBreadcrumbs.vue';
import PassportModal from '@/components/PassportModal.vue';
import DraftIcon from '@/assets/icons/target_icon.svg';
import { ApiService } from '@/services/api.service';

import QRCodeStyling from 'qr-code-styling';

export default {
  name: 'default',
  components: {
    PassportInput,
    PassportDropdown,
    PassportSwitch,
    DownloadIcon,
    PassportButton,
    PassportImageUploader,
    PassportBreadcrumbs,
    PassportModal,
    DraftIcon,
  },
  props: {},
  data() {
    return {
      id: this.$route.params.id,
      associatedId: '',
      qrURL: '',
      isError: false,
      linkError: false,
      quizError: false,
      triviaError: false,
      hintError: false,
      // stampError: false,
      imageSrcError: false,
      videoSrcError: false,
      videoThumbError: false,
      targetName: '',
      targetDescription: '',
      assetDescription: '',
      points: '',
      originalSelectedTarget: '',
      selectedTarget: '',
      selectedGroupId: '',
      selectedQuizId: '',
      selectedTriviaId: '',
      hint: '',
      hintDescription: '',
      linkTitle: '',
      linkPath: '',
      stamp: '',
      imageUpload: '',
      videoUpload: '',
      hintSwitch: false,
      draft: false,
      stampPresigned: '',
      imagePresigned: '',
      videoPresigned: '',
      videoThumbPresigned: '',
      assetSrc: '',
      iconSrc: '',
      showDraftModal: false,
      dontShow: this.$store.state.dontShow,
      assetThumbnailSrc: null,
      targets: [
        {
          label: 'Text only',
          type: 'text',
        },
        {
          label: 'Image',
          type: 'image',
        },
        {
          label: 'Video',
          type: 'video',
        },
        {
          label: 'Link',
          type: 'link',
        },
        {
          label: 'Personality Quiz',
          type: 'quiz',
        },
        {
          label: 'Trivia',
          type: 'trivia',
        },
      ],
      quizzes: [
        {
          label: 'Quiz 1',
          type: 'quiz1',
        },
        {
          label: 'Quiz 2',
          type: 'quiz2',
        },
        {
          label: 'Quiz 3',
          type: 'quiz3',
        },
      ],
      trivias: [
        {
          label: 'Trivia 1',
          type: 'trivia1',
        },
        {
          label: 'Trivia 2',
          type: 'trivia2',
        },
        {
          label: 'Trivia 3',
          type: 'trivia3',
        },
      ],
    };
  },
  created() {},
  computed: {
    groupOptions() {
      return this.$store.state.groupInfo.map((group) => ({
        label: group.name,
        value: group.id,
      }));
    },
    personalityOptions() {
      return this.$store.state.personalityInfo.map((quiz) => ({
        label: quiz.name,
        value: quiz.id,
      }));
    },
    triviaOptions() {
      return this.$store.state.triviaInfo.map((quiz) => ({
        label: quiz.name,
        value: quiz.id,
      }));
    },

    selectedTrivia: {
      get() {
        return this.triviaOptions.find((trivia) => trivia.value === this.selectedTriviaId);
      },
      set(newValue) {
        this.selectedTriviaId = newValue?.value;
      },
    },

    selectedQuiz: {
      get() {
        return this.personalityOptions.find((personality) => personality.value === this.selectedQuizId);
      },
      set(newValue) {
        this.selectedQuizId = newValue?.value;
      },
    },

    selectedGroup: {
      get() {
        return this.groupOptions.find((group) => group.value === this.selectedGroupId);
      },
      set(newValue) {
        this.selectedGroupId = newValue?.value;
      },
    },

    published: {
      get() {
        return !this.draft;
      },
      set(newValue) {
        this.draft = !newValue;
      },
    },

    selectedTargetObject: {
      get() {
        return this.targets.find((target) => target.type === this.selectedTarget);
      },
      set(newValue) {
        this.selectedTarget = newValue?.type;
      },
    },
  },
  mounted() {
    this.getExistingData();
  },
  watch: {
    selectedTarget(newValue) {
      this.$nextTick(() => {
        if (newValue === 'image' && this.originalSelectedTarget === 'image') {
          this.$refs.imageSrc.file = {
            extension: 'html',
          };
          this.$refs.imageSrc.thumbnailPreview = `${process.env.VUE_APP_ASSET_URL}/${this.assetSrc}`;
        } else if (newValue === 'video' && this.originalSelectedTarget === 'video') {
          this.$refs.videoSrc.file = {
            extension: 'html',
          };
          this.$refs.videoSrc.fileName = 'asset.mp4';
          this.$refs.videoThumbnail.file = {
            extension: 'html',
          };
          this.$refs.videoThumbnail.thumbnailPreview = `${process.env.VUE_APP_ASSET_URL}/${this.assetThumbnailSrc}`;
        }
      });
    },
  },
  methods: {
    async uploadVideo() {
      const { extension } = this.$refs.videoSrc.file;
      if (extension === 'mp4') {
        const { data } = await ApiService.post(`/target/${this.id}/asset`, {
          fileFormat: this.$refs.videoSrc.file.extension,
          assetType: 'asset',
        });
        this.assetSrc = data.assetUrl;
        await this.$refs.videoSrc.postS3(data.presignedUrl);
      }
    },

    async uploadThumbnail() {
      let { extension } = this.$refs.videoThumbnail.file;
      if (extension === 'html') return;
      if (extension === 'jpeg') {
        extension = 'jpg';
      }
      const { data } = await ApiService.post(`/target/${this.id}/asset`, {
        fileFormat: extension,
        assetType: 'thumbnail',
      });
      this.assetThumbnailSrc = data.assetUrl;
      await this.$refs.videoThumbnail.postS3(data.presignedUrl);
    },

    async getExistingData() {
      const { name, description, targetGroupId, iconSrc, points, targetAsset, hint, draft } = (
        await ApiService.get(`/target/${this.id}`)
      ).data;
      const { associatedId, assetType, assetSrc, thumbnailSrc, assetDescription } = targetAsset;

      this.$refs.stampImage.file = {
        extension: 'html',
        iconSrc,
      };
      if (iconSrc) {
        this.$refs.stampImage.thumbnailPreview = `${process.env.VUE_APP_ASSET_URL}/${iconSrc}`;
      }
      this.targetName = name;
      this.targetDescription = description;
      this.points = points;
      this.selectedTarget = assetType;
      this.originalSelectedTarget = assetType;
      this.assetThumbnailSrc = thumbnailSrc;
      this.assetSrc = assetSrc;
      this.selectedGroupId = targetGroupId;
      this.draft = draft;
      if (hint) {
        this.hintSwitch = true;
      }
      if (assetType === 'link') {
        this.linkTitle = assetDescription;
        this.linkPath = assetSrc;
      } else if (assetType === 'trivia') {
        this.selectedTriviaId = associatedId;
      } else if (assetType === 'quiz') {
        this.selectedQuizId = associatedId;
      }
    },
    warning() {
      this.$store.commit('setDontShow', true);
      this.showDraftModal = false;
    },
    downloadQRCode() {
      this.qrURL = `${process.env.VUE_APP_URL}/target/${this.id}`;
      const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        type: 'png',
        data: `${this.qrURL}`,
        backgroundOptions: {
          color: '#ffffff',
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 20,
        },
      });
      qrCode.download({ name: `target-${this.id}`, extension: 'png' });
    },
    fileRemoved(file) {
      if (file === 'stamp') {
        this.$refs.stampImage = null;
      } else if (file === 'image') {
        this.$refs.imageSrc = null;
      } else if (file === 'video') {
        this.$refs.videoSrc = null;
      } else if (file === 'videoThumb') {
        this.$refs.videoThumbSrc = null;
      }
    },

    async errorValidation() {
      this.isError = true;
      if (this.selectedTarget === 'quiz') {
        this.associatedId = this.selectedQuiz.value;
      } else if (this.selectedTarget === 'trivia') {
        this.associatedId = this.selectedTrivia.value;
      } else {
        this.associatedId = null;
      }
      if (this.selectedTarget === 'text') {
        this.assetDescription = this.targetDescription;
      }
      // if (!this.$refs.stampImage.hasFile) {
      //   this.stampError = true;
      // } else {
      //   this.stamp = this.$refs.stampImage.file.extension;
      //   this.stampError = false;
      // }
      if (this.selectedTarget === 'image' && !this.$refs.imageSrc.hasFile) {
        this.imageSrcError = true;
      } else if (this.selectedTarget === 'video') {
        if (!this.$refs.videoThumbnail.hasFile) {
          this.videoThumbError = true;
        }
        if (!this.$refs.videoSrc.hasFile) {
          this.videoSrcError = true;
        }
      } else if (this.selectedTarget === 'link' && !this.linkTitle && !this.linkPath) {
        this.linkError = true;
      } else if (this.selectedTarget === 'quiz' && !this.selectedQuiz) {
        this.quizError = true;
      } else if (this.selectedTarget === 'trivia' && !this.selectedTrivia) {
        this.triviaError = true;
      } else if (!this.hint && this.hintSwitch) {
        this.hintError = true;
      }
      if (this.targetName && this.selectedGroup.value && this.targetDescription && this.points && this.selectedTarget) {
        this.isError = false;

        this.hintError = false;
      }
      if (
        this.isError ||
        this.imageSrcError ||
        this.videoSrcError ||
        this.videoThumbError ||
        this.linkError ||
        this.quizError ||
        this.triviaError ||
        this.hintError
      ) {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: 'Please fix the highlighted errors and try to save again.',
        });
      } else if (this.draft && !this.dontShow) {
        this.showDraftModal = true;
      } else {
        await this.saveTarget();
      }
    },

    async saveTarget() {
      this.showDraftModal = false;
      if (this.$refs.stampImage.hasFile) {
        this.stamp = this.$refs.stampImage.file.extension;
        if (this.stamp === 'html') {
          this.iconSrc = this.$refs.stampImage.file.iconSrc;
        } else {
          if (this.stamp === 'jpeg') {
            this.stamp = 'jpg';
          }
          try {
            const { data } = await ApiService.post(`/target/${this.id}/asset`, {
              fileFormat: this.stamp,
              assetType: 'icon',
            });
            this.iconSrc = data.assetUrl;
            await this.$refs.stampImage.postS3(data.presignedUrl);
          } catch (err) {
            console.error(err);
          }
        }
      }

      if (this.selectedTarget === 'image') {
        if (this.$refs.imageSrc.hasFile && this.$refs.imageSrc.file.extension !== 'html') {
          this.imageUpload = this.$refs.imageSrc.file.extension;
          if (this.imageUpload === 'jpeg') {
            this.imageUpload = 'jpg';
          }
          try {
            const { data } = await ApiService.post(`/target/${this.id}/asset`, {
              fileFormat: this.imageUpload,
              assetType: 'asset',
            });
            this.assetSrc = data.assetUrl;
            await this.$refs.imageSrc.postS3(data.presignedUrl);
          } catch (err) {
            console.error(err);
          }
        }
      } else if (this.selectedTarget === 'video') {
        await this.uploadVideo();
        await this.uploadThumbnail();
      } else if (this.selectedTarget === 'link') {
        this.assetSrc = this.linkPath;
        this.assetDescription = this.linkTitle;
      }
      const target = {
        name: this.targetName,
        description: this.targetDescription,
        hintTitle: this.hintTitle || undefined,
        hintText: this.hintText || undefined,
        order: 1,
        points: this.points,
        iconSrc: this.iconSrc || null,
        targetGroupId: this.selectedGroup.value,
        draft: this.draft,
        targetAsset: {
          associatedId: this.associatedId || undefined,
          assetType: this.selectedTarget,
          assetSrc: this.assetSrc || undefined,
          assetDescription: this.assetDescription || undefined,
          thumbnailSrc: this.assetThumbnailSrc || undefined,
        },
      };
      await ApiService.put(`/target/${this.id}`, target).then((res) => {
        if (res.status >= 200 && res.status < 300) {
          this.$notify({
            group: 'primary',
            type: 'success',
            text: 'Target successfully updated.',
          });
          // console.log(res.data);
          this.$router.push('/targets');
        } else {
          this.$notify({
            group: 'primary',
            type: 'error',
            text: 'Something went wrong. Please try again.',
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.examples {
  ::v-deep .passport-breadcrumbs {
    width: 14em;
  }
  .line-break {
    width: 1601px;
    margin-top: 3em;
    margin-bottom: 3em;
    opacity: 0.1;
  }
  ::v-deep .g-input .input-element:not([type='submit'], [type='range']) {
    height: 52px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .target-name {
    width: 27em;
  }
  .page-heading {
    display: flex;
    font-size: 28px;
  }
  .example {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 10px;

    ::v-deep {
      .header {
        padding: 10px;
      }
      .collapse-wrapper > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
.category-heading {
  margin-bottom: 2em;
  font-size: 24px;
  letter-spacing: -0.6px;
}

.target-info,
.hint-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width:90%;
}
.input-field,
.dropdown {
  width: 28em;
  height: 52px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  &__error {
    width: 28em;
    height: 52px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}
// ::v-deep .passport-input .input-element {
//   color: red;
// }
.input-header {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.input-instructions {
  font-size: 14px;
  &__error {
    font-size: 14px;
    color: $red;
  }
}
.target-description,
.hint-description,
.point-holder,
.switch-holder,
.qr-code {
  margin-top: 2em;
}
.points-input {
  width: 200px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.switch {
  margin-top: 1em;
  margin-bottom: 1em;
}
::v-deep .g-checkbox .label.after {
  margin-left: 20px;
}
.download-button {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100px;
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
}
.download-icon {
  width: 19px;
  height: 20px;
  opacity: 0.5;
}
.stamp-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 515px;
  margin-top: 1em;
  margin-bottom: 1.5em;
}
::v-deep .passport-button.default.primary {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
}
.buttons-container {
  display: flex;
}
.target-type,
.hint {
  margin-top: 2em;
}
.image-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 515px;
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.thumbnail-uploader {
  margin-top: 3em;
}
::v-deep .base-file-upload .select-file {
  align-items: center;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding: 90px;
  background-image: url(../assets/images/bg_modal.png);
  background-repeat: no-repeat;
  background-size: cover;
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin-bottom: 40px;
    background: $light-opacity;
    border-radius: 50%;
    svg {
      width: 40px;
      height: auto;
      fill: #fff;
    }
  }
  .modal-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    font-size: 34px;
  }
  .modal-separator {
    width: 200px;
    margin-bottom: 2em;
  }
  .okay-button,
  .warning-button {
    margin-top: 1em;
  }
  .choice-text {
    font-size: 16px;
    text-align: center;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
  }
}
.description-text {
  ::v-deep {
    textarea {
      box-sizing: border-box !important;
      height: 150px !important;
    }
  }
}
</style>

<template>
  <div class="examples">
    <PassportBreadcrumbs :parentRoute="this.$route.meta.parentComponent" :currentRoute="this.$route.name" />
    <span class="page-heading"> Edit Trivia </span>
    <hr class="line-break" />
    <h2 class="category-heading">Trivia Information</h2>
    <div class="trivia-info">
      <div>
        <span class="input-header"> Trivia Title* </span>
        <PassportInput v-model="triviaName" placeholder="Type your trivia name..." />
        <span
          :class="{ 'input-instructions': !isError || triviaName, 'input-instructions__error': isError && !triviaName }"
        >
          Give this trivia asset a name.
        </span>
      </div>
    </div>
    <div class="trivia-description">
      <span class="input-header"> description* </span>
      <PassportInput
        v-model="triviaDescription"
        class="description-text"
        type="textarea"
        placeholder="Add a description to this target…"
      />
      <span
        :class="{
          'input-instructions': !isError || triviaDescription,
          'input-instructions__error': isError && !triviaDescription,
        }"
      >
        Add a description to this trivia.
      </span>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">Trivia Settings</h2>
    <div class="point-holder">
      <span class="input-header"> Point Requirement* </span>
      <span class="input-instructions">
        Select a trivia type. Please note: To setup a trivia or personality quiz trivia, you must first create the asset
        on the Trivia & Quizzes page.
      </span>
      <div class="radio-container">
        <div class="radio-parts">
          <PassportRadio value="Participation" v-model="pointOptions" />
          <span class="radio-instructions"> User receives points for any completion. </span>
        </div>
        <div class="radio-parts">
          <PassportRadio value="Passing Score" v-model="pointOptions" @click="loadModal" />
          <span class="radio-instructions"> User must meet passing percentage to receive points. </span>
        </div>
      </div>
      <div v-if="pointOptions === 'Passing Score'" class="passing-score">
        <div>
          <span class="input-header"> Passing Score* </span>
          <PassportInput v-model="passingScore" placeholder="Type your passing score" />
          <span
            :class="{
              'input-instructions': !isScoreError || passingScore,
              'input-instructions__error': isScoreError && !passingScore,
            }"
          >
            Define how many questions a user needs to answer correctly to pass and receive points.
          </span>
        </div>
        <div class="retakes-holder">
          <span class="input-header">Allow Retakes </span>
          <PassportSwitch class="switch" v-model="retakesEnabled" :label="retakesEnabled ? 'Enabled' : 'Disabled'" />
          <span class="input-instructions"> When enabled, users can retake this trivia. </span>
        </div>
      </div>
      <div class="hint-holder">
        <span class="input-header"> Show Correct Answers </span>
        <PassportSwitch
          class="switch"
          v-model="correctEnabled"
          @click="correctEnabled ? (showModal = false) : (showModal = true)"
          :label="correctEnabled ? 'Enabled' : 'Disabled'"
        />
        <span class="input-instructions">
          When enabled, users will see the correct answers in their results. When disabled, users will only see that
          their answer was correct or incorrect.
        </span>
      </div>
      <PassportModal disableClosingOnMask v-model="showModal">
        <div class="section">
          <div class="icon-container">
            <QuizIcon class="icon" />
          </div>
          <span class="modal-heading">Correct Answers Enabled</span>
          <hr class="modal-separator" />
          <div class="choice-text">
            <span>
              Please note that correct answers is currently enabled. If you would like to hide correct answers, you can
              still disable this feature.
            </span>
          </div>
          <div>
            <PassportButton class="okay-button" @click="showModal = false" label="okay" />
          </div>
        </div>
      </PassportModal>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">Trivia Setup</h2>
    <form @submit.prevent="submitForm">
      <TriviaQuestionPanel ref="questions" :existingQuestions.sync="existingQuestions" />
    </form>
    <hr class="line-break" />
    <div class="buttons-container">
      <PassportButton label="Save trivia" @click="errorValidation" />
      <PassportButton variant="text secondary" label="Cancel" />
    </div>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import PassportInput from '@/components/PassportInput.vue';
import PassportSwitch from '@/components/PassportSwitch.vue';
import PassportRadio from '@/components/PassportRadio.vue';
import TriviaQuestionPanel from '@/components/TriviaQuestionPanel.vue';
import PassportModal from '@/components/PassportModal.vue';
import QuizIcon from '@/assets/icons/quizzes_inactive.svg';
import PassportBreadcrumbs from '@/components/PassportBreadcrumbs.vue';
import { ApiService } from '@/services/api.service';
// import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'default',
  components: {
    PassportInput,
    PassportSwitch,
    PassportRadio,
    PassportButton,
    TriviaQuestionPanel,
    PassportModal,
    QuizIcon,
    PassportBreadcrumbs,
  },
  props: {},
  data() {
    return {
      id: this.$route.params.id,
      questions: [],
      answers: [],
      isError: false,
      isScoreError: false,
      passingScore: '',
      triviaName: '',
      triviaDescription: '',
      showModal: false,
      pointOptions: 'Participation',
      correctEnabled: true,
      retakesEnabled: false,
      existingQuestions: [],
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getExistingData();
  },
  watch: {},
  methods: {
    async getExistingData() {
      await ApiService.get(`/quiz/trivia/${this.id}`).then((response) => {
        // console.log(response.data);
        this.triviaName = response.data.name;
        this.triviaDescription = response.data.description;
        if (response.data.requirePassingScore) {
          this.pointOptions = 'Passing Score';
        } else {
          this.pointOptions = 'Participation';
        }
        this.passingScore = response.data.passingScore;
        if (response.data.showCorrectAnswers) {
          this.correctEnabled = true;
        } else {
          this.correctEnabled = false;
        }
        if (response.data.allowRetakes) {
          this.allowRetakes = true;
        } else {
          this.allowRetakes = false;
        }
        response.data.questions.forEach((question, index) => {
          const label = question.questionType === 'single_answer' ? 'Single Answer' : 'Multiple Choice';
          const answers = question.answers.map((answer) => ({
            id: answer.id,
            text: answer.answerText,
            isCorrect: answer.isCorrect,
          }));
          this.existingQuestions.push({
            ...question,
            type: {
              label,
              key: question.questionType,
            },
            text: question.questionText,
            questionOptions: answers,
            order: index + 1,
          });
        });
      });
    },
    loadModal() {
      if (this.correctEnabled === true) {
        this.showModal = true;
      }
    },
    async errorValidation() {
      this.isError = true;
      if (this.pointOptions === 'Passing Score' && this.passingScore === '') {
        this.isScoreError = true;
      } else {
        this.isScoreError = false;
      }
      if (this.pointOptions === 'Participation') {
        this.requirePassingScore = false;
      } else {
        this.requirePassingScore = true;
      }
      const trivia = {
        name: this.triviaName,
        description: this.triviaDescription,
        passingScore: this.passingScore || undefined,
        requirePassingScore: this.requirePassingScore,
        showCorrectAnswers: this.correctEnabled,
        allowRetakes: this.retakesEnabled,
        questions: this.existingQuestions.map((question) => ({
          id: question.id,
          questionText: question.text,
          questionType: question.type.key,
          answers: question.questionOptions.map((option) => ({
            id: option.id,
            answerText: option.text,
            isCorrect: option.isCorrect,
          })),
        })),
      };
      if (this.isError && this.isScoreError) {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: 'Please fix the highlighted errors and try to save again.',
        });
      } else {
        await ApiService.put(`/quiz/trivia/${this.id}`, trivia).then((response) => {
          // console.log(response);
          if (response.status >= 200 && response.status < 300) {
            this.$notify({
              group: 'primary',
              type: 'success',
              text: 'Trivia saved successfully.',
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.examples {
  .line-break {
    width: 1601px;
    margin-top: 3em;
    margin-bottom: 3em;
    opacity: 0.1;
  }
  .page-heading {
    display: flex;
    font-size: 28px;
  }
  .example {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 10px;

    ::v-deep {
      .header {
        padding: 10px;
      }
      .collapse-wrapper > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
.category-heading {
  margin-bottom: 2em;
  font-size: 24px;
  letter-spacing: -0.6px;
}

.trivia-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width:90%;
}
::v-deep .g-input .input-element:not([type='submit'], [type='range']) {
  width: 515px;
  height: 50px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.input-header {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.input-instructions {
  margin-top: 2em;
  font-size: 14px;
  &__error {
    color: $red;
  }
}
.radio-instructions {
  margin-left: 3.45em;
  font-size: 14px;
}
.trivia-description,
.passing-score,
.retakes-holder,
.hint-holder {
  margin-top: 2em;
}
.point-holder {
  display: flex;
  flex-direction: column;
}
.switch {
  margin-top: 1em;
  margin-bottom: 1em;
}
::v-deep .g-checkbox .label.after {
  margin-left: 20px;
}
::v-deep .passport-button.default.primary {
  font-size: 14px;
  text-transform: uppercase;
}
.okay-button {
  margin-top: 1.5em;
}
.buttons-container {
  display: flex;
  align-items: center;
}
.trivia-type,
.radio-container {
  margin-top: 2em;
}
::v-deep .g-radio .label.after {
  padding: 20px;
}
.radio-parts {
  margin-bottom: 1em;
}

.modal-heading {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  font-size: 34px;
}
.modal-separator {
  width: 200px;
  margin-bottom: 2em;
}
.choice-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 401px;
  padding: 30px;
  margin: auto;
  margin-bottom: 1em;
  background: #ffffff26 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
}
.heading {
  margin-bottom: 1em;
  font-size: 34px;
}
.choice-text {
  font-size: 16px;
  text-align: center;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding: 90px;
  background-image: url(../assets/images/bg_modal.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 40px;
  background: $light-opacity;
  border-radius: 50%;
  svg {
    width: 40px;
    height: auto;
    fill: #fff;
  }
}
::v-deep .passport-breadcrumbs {
  width: 12em;
}
// .description-text {
//   width: 1061px;
//   height: 150px;
// }
</style>

<template>
  <div class="questions-container">
    <section
      v-for="(question, index) in questions"
      :key="question.questionId"
      :ref="question.questionId"
      class="question"
    >
      <div class="form-row">
        <div class="order form-input-10">
          <label class="text--input-header">Order</label>
          <PassportDropdown
            v-model="question.order"
            class="short cms-input"
            :options="orderOptions"
            open-direction="bottom"
            :searchable="false"
            :allow-empty="false"
            :show-labels="false"
            @input="changeOrder(question)"
          />
        </div>
        <div class="form-input-90">
          <label class="text--input-header">Question Type*</label>
          <PassportDropdown
            v-model="question.type"
            :options="questionTypes"
            :searchable="false"
            :allow-empty="false"
            :show-labels="false"
            placeholder="Select a question type"
            required
            track-by="key"
            label="label"
            class="cms-input"
            @select="handleQuestionTypeChange(question, index)"
          />
        </div>
      </div>
      <div v-if="question.type.key" class="form-row">
        <div class="form-input-100">
          <label class="text--input-header">Question*</label>
          <PassportInput v-model="question.text" class="form-input" type="text" required showCount :maxlength="500" />
        </div>
      </div>
      <div
        v-if="
          ((question.questionOptions && question.questionOptions.length) ||
            (question.questionAnswers && question.questionAnswers.length)) &&
          question.type
        "
      >
        <!-- multiple_choice -->

        <div v-if="question.type.key === 'multiple_choice'" class="form-row wrap-row">
          <div class="form-input-100">
            <label class="text--input-header">Answer Options*</label>
            <p class="subtext">
              multiple_choice questions must have a minimum of two answer options. Check at least one option as the
              correct answer.
            </p>
            <div v-for="answer in question.questionOptions" :key="answer.answerId" class="answer-field">
              <PassportCheckbox
                v-model="answer.isCorrect"
                class="answer-checkbox"
                @updated="updateElementValidity($event.target)"
              />
              <PassportInput v-model="answer.text" class="form-input" type="text" :maxlength="50" required showCount />
              <PassportButton
                :disabled="question.questionOptions.length < 3"
                variant="text danger"
                class="remove-button"
                @click.prevent="removeAnswerOption(answer.answerId, index)"
              >
                Remove <span class="button-icon"><IconX /></span>
              </PassportButton>
            </div>
          </div>
          <PassportButton variant="text" class="add-button" @click.prevent="addAnswerOption(index, 'multiple_choice')">
            Add Option <span class="button-icon"><IconPlus /></span>
          </PassportButton>
          <PassportButton
            :disabled="index === 0"
            variant="text danger"
            class="remove-button"
            @click.prevent="removeQuestion(question.questionId, index)"
          >
            Remove Question <span class="button-icon"><IconX /></span>
          </PassportButton>
        </div>

        <!-- single_answer -->

        <div v-else-if="question.type.key === 'single_answer'" class="form-row wrap-row">
          <div class="form-input-100">
            <label class="text--input-header">Answer Options*</label>
            <p class="subtext">
              Single choice questions must have a minimum of two answer options. Select one option as the correct
              answer.
            </p>
            <div class="answer-field" v-for="answer in question.questionOptions" :key="answer.answerId">
              <PassportRadio
                :checked="answer.isCorrect"
                :buttonID="answer.answerId"
                :name="question.questionId"
                :value="answer.answerId || answer.id"
                hideLabel
                @updated="updateRadio($event, answer, question.questionId)"
              />
              <PassportInput
                class="form-input input-spacing"
                type="text"
                v-model="answer.text"
                required
                showCount
                :maxlength="50"
              />
              <PassportButton
                variant="text danger"
                class="remove-button"
                :disabled="question.questionOptions.length < 3"
                @click.prevent="removeAnswerOption(answer.answerId || answer.id, index)"
              >
                Remove <span class="button-icon"><IconX /></span>
              </PassportButton>
            </div>
          </div>
          <PassportButton variant="text" class="add-button" @click.prevent="addAnswerOption(index, 'single_answer')">
            Add Option <span class="button-icon"><IconPlus /></span>
          </PassportButton>
          <PassportButton
            :disabled="index === 0"
            variant="text danger"
            class="remove-button"
            @click.prevent="removeQuestion(question.questionId, index)"
          >
            Remove Question <span class="button-icon"><IconX /></span>
          </PassportButton>
        </div>
      </div>
    </section>
    <PassportButton variant="text" class="add-button" @click.prevent="addQuestion">
      Add Question <span class="button-icon"><IconPlus /></span>
    </PassportButton>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import PassportInput from '@/components/PassportInput.vue';
import PassportDropdown from '@/components/PassportDropdown.vue';
import PassportCheckbox from '@/components/PassportCheckbox.vue';
import PassportButton from '@/components/PassportButton.vue';
import PassportRadio from '@/components/PassportRadio.vue';
import IconPlus from '@/assets/icons/plus.svg';
import IconX from '@/assets/icons/x_icon.svg';

export default {
  components: {
    PassportInput,
    PassportDropdown,
    PassportCheckbox,
    PassportRadio,
    PassportButton,
    IconX,
    IconPlus,
  },
  data() {
    return {
      selectedQuestionType: null,
      questionTypes: [
        {
          label: 'Multiple Choice',
          key: 'multiple_choice',
        },
        {
          label: 'Single Answer',
          key: 'single_answer',
        },
      ],
    };
  },
  props: {
    existingQuestions: {
      type: Array,
    },
  },
  computed: {
    questions: {
      get() {
        return this.existingQuestions || [];
      },
      set(value) {
        this.$emit('update:existingQuestions', value);
      },
    },
    sortedQuestions() {
      return this.sortQuestions(this.questions);
    },
    orderOptions() {
      const options = [];
      for (let i = 0; i < this.questions.length; i += 1) {
        options.push(i + 1);
      }
      return options;
    },
  },
  methods: {
    sortQuestions(questions) {
      return questions.slice().sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));
    },
    addAnswerOption(index, type) {
      if (this.questions[index]) {
        this.questions[index].questionOptions.push(this.generateAnswers(type, this.questions[index].questionId));
      } else {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `The question at index ${index} was not found`,
        });
      }
    },
    removeAnswerOption(answerId, index) {
      if (this.questions[index]) {
        this.questions[index].questionOptions = this.questions[index].questionOptions.filter(
          (a) => a.answerId !== answerId,
        );
      } else {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `The answer option with id ${answerId} was not found`,
        });
      }
    },
    removeQuestion(questionId, index) {
      if (this.questions[index]) {
        // update array to avoid any gaps in numbers. ex 1 3 5 -> 1 2 3
        const localQuestions = this.questions.filter((q) => q.questionId !== questionId);
        const sortedQuestions = this.sortQuestions(localQuestions);
        for (let i = 0; i < sortedQuestions.length; i += 1) {
          const questionIndex = localQuestions.findIndex(
            (question) => question.questionId === sortedQuestions[i].questionId,
          );
          localQuestions[questionIndex].order = i + 1;
        }
        this.questions = [...localQuestions];
      } else {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: `The question at index ${index} was not found`,
        });
      }
    },
    addQuestion() {
      this.questions.push({
        questionId: `temp-${uuidv4()}`,
        text: '',
        type: '',
        order: this.questions.length + 1,
        questionOptions: [],
        questionAnswers: [],
      });
    },
    changeOrder(changedQuestionCard) {
      const reorderedQuestions = [...this.sortedQuestions];
      const newIndex = changedQuestionCard.order - 1;
      const oldIndex = reorderedQuestions.findIndex(
        (question) => question.questionId === changedQuestionCard.questionId,
      );
      if (oldIndex > -1) {
        // Remove item from array
        reorderedQuestions.splice(oldIndex, 1);
        // Insert item at new index
        reorderedQuestions.splice(newIndex, 0, changedQuestionCard);
      }
      // console.log(reorderedQuestions);
      // take the indicies of the reordered array and assign those to the order key on the main array
      const localQuestions = [...this.questions];
      for (let i = 0; i < reorderedQuestions.length; i += 1) {
        // Find the reordered array element find where it is in the main array to update it's order key
        const questionIndex = localQuestions.findIndex(
          (question) => question.questionId === reorderedQuestions[i].questionId,
        );
        // assign as current reordered array key + 1 for a start at 1
        localQuestions[questionIndex].order = i + 1;
      }
      this.questions = [...localQuestions];
    },
    handleQuestionTypeChange(question, questionIndex) {
      this.$nextTick().then(() => {
        if (question && questionIndex !== undefined) {
          // Update questionTypeId
          question.questionTypeId = question.type.id;
          // Clear question options and answers on type change
          if (question.questionOptions && question.questionOptions.length) {
            this.questions[questionIndex].questionOptions = [];
          }
          if (question.questionAnswers && question.questionAnswers.length) {
            this.questions[questionIndex].questionAnswers = [];
          }

          // Append empty question objects based on type
          if (question.type.key === 'open answer' || question.type.key === 'fill-in-the-blank') {
            this.questions[questionIndex].questionAnswers.push(
              this.generateAnswers(question.type.key, question.questionId),
            );
          } else {
            this.questions[questionIndex].questionOptions = this.generateAnswers(
              question.type.key,
              question.questionId,
              2,
            );
          }
        }
      });
    },
    // Returns single_answer by default or many answers if question type is multiple_choice
    /**
     * @param {string} questionType
     * @param {string} questionId
     * @param {number} _count
     * @returns {object|Array}
     */
    generateAnswers(_type, questionId, _count = 1) {
      const answers = [];
      const type = _type.toLowerCase();
      let count = _count;

      while (count) {
        if (type === 'open answer' || type === 'fill-in-the-blank') {
          answers.push({
            text: '',
            questionId,
            answerId: `temp-${uuidv4()}`,
          });
        } else if (type === 'matching') {
          answers.push({
            match1Text: '',
            match2Text: '',
            answerId: `temp-${uuidv4()}`,
            questionId,
            newMatch: true,
            isCorrect: true,
          });
        } else {
          answers.push({
            text: '',
            questionId,
            answerId: `temp-${uuidv4()}`,
            isCorrect: false,
          });
        }
        count -= 1;
      }
      return answers.length === 1 ? answers[0] : answers;
    },
    /**
     * Validate checkboxes and radio buttons before submission
     * @returns {boolean}
     */
    validateQuiz() {
      let isValid = true;
      this.questions.forEach((question) => {
        if (question.type.key === 'multiple_choice') {
          const answerInput = this.$refs[question.questionId][0].querySelector('input[type="checkbox"]');
          if (!question.questionOptions.some((option) => option.isCorrect)) {
            isValid = false;
            this.updateElementValidity(answerInput, 'At least one answer must be selected.');
          } else {
            this.updateElementValidity(answerInput);
          }
        } else if (question.type.key === 'single_answer') {
          const answerInput = this.$refs[question.questionId][0].querySelector('input[type="radio"]');
          if (!question.questionOptions.some((option) => option.isCorrect)) {
            isValid = false;
            this.updateElementValidity(answerInput, 'One answer must be selected.');
          } else {
            this.updateElementValidity(answerInput);
          }
        }
      });
      return isValid;
    },
    /**
     * Update an element's custom validity with a message.
     */
    updateElementValidity(element, message = '') {
      element.setCustomValidity(message);
    },
    /**
     * Finds input element based on questionId and className and updates it to valid.
     */
    updateInputsValid(questionId, className) {
      this.updateElementValidity(this.$refs[questionId][0].getElementsByClassName(className)[0]);
    },
    updateCheckbox(event) {
      if (event?.target) {
        this.updateElementValidity(event.target);
      }
    },
    /**
     * Updates a series of radio buttons to have a single radio input selected.
     */
    updateRadio(event, answer, questionId) {
      // Set checked radio answer to true
      answer.isCorrect = true;
      // Set all other answers to false
      this.questions
        .find((question) => question.questionId === questionId)
        ?.questionOptions?.forEach((option) => {
          if (option.answerId !== answer.answerId || option.id !== answer.id) {
            option.isCorrect = false;
          }
        });
      // Update validity
      if (event?.target) {
        this.updateElementValidity(event.target);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.questions-container {
  display: block;
  width: 100%;
  padding: 35px;

  .question {
    padding: 40px;
    margin-bottom: 40px;
    background-color: rgba(#fff, 0.15);
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    .form-row,
    .form-row-double {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .form-input {
        width: 100%;
      }

      .form-input-100 {
        width: 100%;
      }

      .form-input-90 {
        width: 90%;
      }

      .order {
        margin-right: 30px;
      }
      .form-input-10 {
        width: 10%;
      }
    }
    .wrap-row {
      flex-wrap: wrap;
    }
    .subtext {
      margin: 10px 0 0;
      margin-bottom: 15px;
      font-size: 12px;
      opacity: 0.5;
    }

    .answer-field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
      .answer-checkbox {
        margin-right: 30px;
      }
      .form-input {
        margin-bottom: -15px;
      }
      .remove-button {
        margin-left: 15px;
      }
    }

    .matching {
      flex-wrap: wrap;
      label {
        width: 100%;
      }

      .match-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;

        .form-input-45 {
          width: 45%;
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  }

  .add-button {
    .button-icon {
      width: 20px;
      height: 20px;
    }
  }

  .add-button,
  .remove-button {
    display: flex;
    align-items: center;
    font-size: 14px !important;
    letter-spacing: 0.7px;
    cursor: pointer;
    background: none;
    border: none;
  }

  .button-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 15px;
  }

  .input-spacing {
    margin-left: 20px;
  }
}
</style>
